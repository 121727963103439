:root {
    --page-width: 1400px;
}

main.main-authors {

    display: flex;
    flex-direction: column;

    align-items: center;
    font-size: clamp(1rem, 2.5vw, 2rem);

}
