
.grid {
    display: grid;
    grid-template-columns: 2fr 6fr 1fr;
    border-top: 1px solid var(--border-clr);
    border-right: 1px solid var(--border-clr);
}

.grid span {
    padding: 8px 4px;
    border-left: 1px solid var(--border-clr);
    border-bottom: 1px solid var(--border-clr);

    transition: color .1s;
}

.grid > .row-wrapper:hover {
    color: #666666;
}

#dubbingers {
    max-width: var(--page-width);
    font-size: .5em;
    margin: 1em;

}

.row-wrapper {
    display: contents;
}
  