:root {
    --page-width: 1400px;
}

main.faq-main {
    
    display: flex;
    flex-direction: column;

    align-items: center;
    font-size: clamp(1rem, 2.5vw, 2rem);

}

#faq {

    max-width: var(--page-width);
    padding: 1em;
    font-size: .7em;
    line-height: 1.6em;
}

.qa {

    --qa-corner: 50px;
    --qa-border: 3px;
    --qa-extra: 5px;


    background: var(--primary-clr);

    max-width: 800px;
    margin: 3em 1em;
}


.q {
    background: var(--primary-clr);
    
    min-height: 150px;
    padding: calc(max(1em,var(--qa-corner))) calc(max(3em,var(--qa-corner)));

    border: solid var(--qa-border) var(--negro1);
    border-radius: var(--qa-corner);


    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.q span {
    font-weight: bold;
    font-size: 1.4em;
}

.q::before {

    font-weight: bold;

    text-align: center;

    z-index: 1;

    position: absolute;
    width: 3.5em;
    height: 3.5em;
    line-height: 3.5em;

    bottom: calc(-5px + var(--qa-corner));
    left: -1.75em;

    content: "?";

    background: var(--negro4);

    border: solid 2px var(--negro1);

    border-radius: 50%;
}

.q:after {
    content: "";
    width: var(--qa-corner);
    height: var(--qa-corner);

    position: absolute;
    left: calc(-1 * var(--qa-border));
    bottom: calc(-1 * var(--qa-border));

    background-color: var(--primary-clr);
}

.a {
    border: solid var(--qa-border) var(--negro1);
    border-radius: calc(var(--qa-corner));
    background-color: var(--primary-clr);

    transform: translateY( calc(-1 * var(--qa-border)) );

    min-height: calc(2 * var(--qa-corner) + var(--qa-extra));

    position: relative;

    padding: calc(max(1em,var(--qa-corner))) calc(max(3em,var(--qa-corner)));

}

.a::before {

    text-align: center;


    position: absolute;
    width: 3.5em;
    height: 3.5em;
    line-height: 3.5em;

    top: calc(-0.5 * 3.5em);
    left: -1.75em;

    content: "\1F4AC";

    background: var(--negro5);
    border: solid 2px var(--negro1);
    transform: translateY(var(--qa-corner));

    border-radius: 50%;
}



.a::after {
    content: "";
    width: var(--qa-corner);
    height: calc(100% - var(--qa-corner));

    position: absolute;
    right: calc(-1 * var(--qa-border));
    top: calc(-1 * var(--qa-border));

    background-color: var(--primary-clr);


}

.a > .pseudo {
    z-index: 1;
    width: calc(var(--qa-extra) + var(--qa-corner));
    height: calc(var(--qa-extra) + var(--qa-corner));
    background-color: var(--primary-clr);
    position: absolute;
    top: calc(-1 * var(--qa-corner) - var(--qa-extra));
    right: calc(-1 * var(--qa-border));

    border-right: solid var(--qa-border) var(--negro1);
    border-bottom: solid var(--qa-border) var(--negro1);
    border-bottom-right-radius: var(--qa-corner);
}


@media screen and (max-width: 750px) {
    .qa {
        padding: 1em;
        margin: 1em;

    }
    #faq {
        overflow-x: hidden;
        padding: 0;
        margin: 0;
    }

}
