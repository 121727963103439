
.linker {
    display: flex;
    border-bottom: black solid 3px;
    padding: .2em 1.5em;
}

.copyright {
    margin-left: auto;
    line-height: 2em;
    letter-spacing: normal;
}


.links > a {
    margin: 0px 2px;
}


.links img {
    width: 2em;
    height: 2em;

    opacity: 80%;
    transition: opacity .2s;
}

.links img:hover {
    opacity: 100%;
}

