
main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#download {
    position: relative;
    max-width: var(--page-width);
    font-size: clamp(1rem, 2.5vw, 2rem);
}

#download .left h1 {
    padding: 0;
    margin: 0;
    font-size: 3em;
    font-weight: 1000;
    margin-bottom: .5em;
}

#description {
    font-family: 'Ruda', sans-serif;
    font-size: .6em;
    line-height: 1.7em;
}

#description ul {
    margin-top: 1em;
    line-height: 130%;
    font-size: .8em;
}


#download .right p {
    padding: 0;
    margin: 0;
}

.mod-features {
    padding: 0;
}


#assets {
    width: 100%;
    align-self: start;

    border-top: solid 2px #1f1f1f;
    font-size: .8em;

    display: flex;
    flex-direction: column;
    align-items: center;

}

#assets > ul {
    margin-top: 0;
    align-self: flex-start;
    padding-left: 1em;
}

#assets p {
    font-size: 1.3em;
    font-weight: bold;
}

#assets a {
    color: currentColor;
    text-decoration: none;
    line-height: 140%;
    opacity: 80%;
    transition: opacity ease-in .2s;
}

#assets a:hover {
    opacity: 100%;
}

#download-breaker {

    --breaker-height: 2.5em;

    width: 100%;
    height: var(--breaker-height);
    fill: var(--negro2);

    position: absolute;
    bottom: calc(-1 * var(--breaker-height) / 2);
    left: 0;
}



.img-u12-wrapper {
    align-self: center;
    /* border: solid 3px black; */
    border-radius: 10px;
    box-shadow: 3px 3px 5px rgba(36, 36, 36, 0.781);

    display: block;
    max-width: 100%;

    display: flex;
    overflow: hidden;

    margin-top: .5em;
    max-width: 700px;
}

#img-u12 {
    max-width: 100%;
    height: fit-content;
}






@media screen and (max-width: 1000px) {

    #description ul {
        font-size: 1em;
    }
        
}
