


.panel {
    border: 0;
    margin: 0;
    padding: 1em 1em;
    display: flex;
    flex-direction: row;
}

.left {
    flex: 0 1 50%;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.right {
    flex: 1 1 70%;
    padding: 1.2rem;
}




@media screen and (max-width: 1100px) {
    .panel {
        flex-direction: column;
        padding-left: 1em;
        padding-right: 1em;
    }

    .left {
        flex-basis: 100%;
        padding-top: 0;
    }
    
    .right {
        flex-basis: 100%;
        padding-bottom: 0;
    }

    .navigation {
        margin-left: .2em;
        margin-right: .2em;
    }

}