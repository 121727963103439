
@import url('https://fonts.googleapis.com/css?family=Orelega+One&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Red+Hat+Display&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Reggae+One&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Ruda&subset=latin,latin-ext');

@font-face {
  font-family: 'Balthazar';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/balthazar/v10/d6lKkaajS8Gm4CVQjFEfzh7p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}  

@font-face {
  font-family: 'SecretMansion';
  font-style: normal;
  font-weight: 400;
  src: url(/public/fonts/SecretMansion-Ea1RW.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}  

@font-face {
  font-family: 'Duru Sans';
  font-style: normal;
  font-weight: 400;
  /*src: url(/public/fonts/DuruSans-Regular.ttf) format('truetype');    */
  src: url(/public/fonts/SecretMansion-Ea1RW.ttf) format('truetype');    
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}  

html {
  scroll-behavior: smooth;
}

:root {
/*
  --color1: #A58105;
  --color2: #BD9B15;
  --color3: #DDB639;
  --color4: #FFDF01;
  --color5: #FFCC01;
  --color6: #ebe2c2;
*/
  --color1: #055da5;
  --color2: #157abd;
  --color3: #39afdd;
  --color4: #01ccff;
  --color5: #01ccff;
  --color6: #c2ebeb;

  --negro1: #000000;
  --negro2: #1b1b1b;
  --negro3: #262626;
  --negro4: #2f2f2f;
  --negro5: #444444;
  --negro6: #a0a0a0;

  --primary-clr: var(--negro3);
  --secondary-clr: var(--color1);
  --border-clr: var(--negro5);
  --text-clr: var(--negro6);

  --page-width: 1400px;
}


body, html {
  font-family: 'Reggae One', sans-serif;


  margin: 0;
/*  font-family: 'Red Hat Display', sans-serif;*/
  font-family: 'Ruda', sans-serif;
/*  font-family: sans-serif;*/
  color: var(--negro6);

  text-shadow: 
      1px 0.5px 1px var(--negro3);

  letter-spacing: .1em;
}


::selection {
  background: var(--negro1); /* WebKit/Blink Browsers */
}

h1, h2, h3 {
  font-family: 'Orelega One', serif;
}

a:hover {
  color: currentColor !important;
}




/*
li {
  list-style: none;
  padding-inline-start: 1ch;

  margin-left: 50px;
}

li::before{
  margin-right: 50px;
  content: "\00BB";
}
*/
ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

li {
  padding-left: 1em;
  text-indent: -1em;
}

li:before {
  content: "+";
  content: "\00BB";
  padding-right: 5px;
}

.reveal{
  opacity: 0;
  transition: .75s all ease;
}

.reveal.active{
  transform: translateY(0) !important;
  transform: translateX(0) !important;
  opacity: inherit;
}


:root {
  --bs-body-font-family: 'Ruda', 'sans-serif' !important;
  --bs-body-color: var(--text-clr) !important;
}

