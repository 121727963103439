.navigation {

    padding: .2em 1.5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 3em;
}

.navigation > a {
    margin: 0 1em;
    text-decoration: none;
    font-family: 'Balthazar';
    text-transform: uppercase;

    transition: opacity .2s;
    opacity: 80%;
}

.navigation > a:hover {
    opacity: 100%;
}


@media screen and (max-width: 1200px) {

    .navigation {
        font-size: 2em;
    }
  }
  

@media screen and (max-width: 850px) {

    .navigation > a {
        margin: 0 .3em;
        padding: 0;
    }

    .navigation {
        font-size: 3em;
        margin: 0;
        padding: 0;
    }
  }
  