.aside-sticky {

    border-left: solid 3px var(--negro2);

    min-height: calc(100vh - 60px - 1em - 1em);


    width: 100%;

    display: flex;


}

.date-version {
  margin-bottom: 100px;
}

#the-aside {
/*    z-index: 2;*/



    background-color: var(--primary-clr);
    overflow-y: auto;
    max-width: 400px;
    min-width: 400px;

    padding: 1em;

}

.sticky2 {
    position: fixed;
    top: calc(60px + 1em);
    z-index: 1;
/*    width: 400px;*/

  }

  .aside-img { /* TODO center */
      max-width: 300px;
      margin: 1em;
      padding: 2em;
      border-bottom: 2px solid var(--border-clr);
  }

  .aside-content {

    align-self: flex-start;

      display: flex;
      flex-direction: column;
      align-items: center;

    margin-left: .25em;
    margin-top: 2em;
    padding-bottom: 10em;
    border-left: solid 3px var(--negro2);
    padding-left: 1em;

  }

  .mod-available {

        margin-top: 1em;
        border-top: solid 2px var(--border-clr);
        padding-top: .5em;

        display: flex;
        flex-direction: column;
        align-items: center;

  }

  .mod-available-concrete {
      width: 100%;
      display: flex;
      justify-content: space-between;
  }

  .aside-content > h2 {
      margin-top: 1em;
  }

  .aside-content > button {
      margin: 1em 0em;
  }


  .aside-content .download-btn {
    margin: 1em 0;
  }