
#author {

    max-width: var(--page-width);
    padding: 1em;
    font-size: .7em;
    line-height: 1.6em;
}

#author figure {
    max-width: 30%;    
    margin-bottom: 10px;
}

#author figure.img-TheKetrab {
    float: right;
    margin-left: 30px;
}

#author figure.img-Screen {
    max-width: 60%;
    float: left;
    margin-right: 30px;
}

#author figure.img-OldScreens {
    max-width: 100%;
    margin: 30px;
    margin-right: 5%;
    margin-left: 5%;
}

#author figure img {
    width: 100%;
    border-radius: 10px;
    box-shadow: black 1px 1px 5px;
}

#author figure img.double {
    width: 50%;
}




#author figure p {
    margin: 10px;
    text-align: center;
}

@media screen and (max-width: 850px) {

    #author figure {
        max-width: 60%;
        margin-bottom: 10px;
        margin-top: 10px;

        margin-right: auto !important;
        margin-left: auto !important;
        float: none !important;
    }

    figure.img-Screen {
        max-width: 90% !important;
    }
}


.inside-archive-figure {
    display: flex;
}

.inside-archive-figure > * {
    margin: .2em;
}